.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media (max-width: 768px) {
    .footer-container {
        height: 100px;
    }

    .socials-img {
        width: 35px;
        height: auto;
    }
  }

  @media (min-width: 769px) {
    .footer-container {
        height: 150px;
    }
  }
  