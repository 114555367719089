h2 {
    font-weight: 600;
}

@media (min-width: 451px) {
    .socials-icon {
        height: 25px;
        width: auto;
        padding: 15px 0px 0px 15px;
    }
}

@media (min-width: 769px) {
    .header-container {
        padding: 10px 55px 0px 45px;
    }

    .header-left {
        letter-spacing: 0.3rem;
    }
    
    .header-right {
        letter-spacing: 0.2rem;
    }

    .socials-icon {
        height: 30px;
        width: auto;
        padding: 13px 0px 0px 15px;
    }
}

@media (max-width: 768px) {
    h2 {
        padding: 10px 0px 0px 0px;
        font-size: 18px;
    }

    .header-container {
        padding: 0px 20px 0px 20px;
    }


    .header-left {
        letter-spacing: 0.05rem;
    }
    
    .header-right {
        letter-spacing: 0.1rem;
    }
}
  
@media (max-width: 450px) {
    h2 {
        padding: 15px 0px 0px 0px;
        font-size: 14px;
    }

    ul {
        padding: 6px 0px 0px 0px;
        font-size: 12px;
    }

    .socials-icon {
        height: 20px;
        width: auto;
        padding: 17px 0px 0px 15px;
    }   
}

ul {
    list-style-type: none;
    display: flex;
}

li {
    padding: 0px 0px 0px 15px;
}

.nav-text {
    color: rgb(48, 48, 48);
    text-decoration: none;
}

.nav-text:hover {
    border-bottom: rgb(95, 95, 95) solid;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.header-left {
    padding: 5px 0px 0px 0px;
}

.header-right {
    display: flex;
    padding: 15px 0px 0px 0px;
}