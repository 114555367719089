.contact-container {
    text-align: center;
    margin: 30px 0px 0px 0px;
}

.profile-pic-container {
    height: 750px;
    justify-content: center;
    width: 100%;
}

.profile-pic {
    width: 90%;
    height: 90%;
    max-width: 1200px; /* Adjust as needed */
    
    object-fit:cover;
}

.contact-blocks {
    display: flex;
    flex-direction: row;
    gap: 20px; /* Add gap between blocks */
    padding: 0 20px; /* Add padding to handle content on smaller screens */
}

.left-contact {
    text-align: left;
    width: 40%;
    padding: 20px; /* Add padding for better spacing */
    box-sizing: border-box; /* Ensure padding is included in width */
}

.right-contact {
    text-align: left;
    width: 60%;
    padding: 20px;
    font-size: 15px;
}

.skills-header {
    letter-spacing: 0.3rem;
    font-weight: 500;
    margin: 0;
    font-size: 18px;
}

.skill {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.3rem;
    font-size: 18px;
}

.contact-details {
    display: flex;
    flex-direction: row;
    gap: 20px; /* Add gap between email and phone sections */
}

.email, .phone {
    flex: 1; /* Make each section take equal width */
}

.contact-label {
    font-weight: 600;
}

.contact-btn {
    margin: 15px 0px 0px 0px;
    background-color: black;
    color: white;
    height: 50px;
    width: 250px;
    border: none;
    font-family: 'Poppins';
    letter-spacing: 0.1rem;
}

.contact-btn:hover {
    cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-blocks {
        flex-direction: column;
    }

    .left-contact, .right-contact {
        width: 100%;
        padding: 10px; /* Reduce padding on smaller screens */
    }

    .profile-pic-container {
        height: 500px;
        justify-content: center;
        width: 100%;
    }


    .contact-details {
        flex-direction: column;
    }

    .email, .phone {
        width: 100%; /* Full width on smaller screens */
    }

    .contact-btn {
        width: 100%; /* Full width button */
    }
}
