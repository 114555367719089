@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
    
  }
  
  .modal-form label {
    margin-bottom: 15px;
    font-family: 'Poppins';
  }
  
  .modal-form input,
  .modal-form textarea {
    width: calc(100% - 20px); /* Fix input width inside the modal */
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding and width behave correctly */
  }
  
  .submit-btn,
  .close-btn {
    background-color: black;
    color: white;
    padding: 12px 20px; /* Increased padding for larger button size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Poppins';
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: 1.2px; /* Add letter spacing */
  }
  
  .submit-btn:hover,
  .close-btn:hover {
    background-color: #333;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .modal-content {
      max-width: 400px;
      padding: 15px;
    }
  
    .submit-btn,
    .close-btn {
      padding: 10px 18px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .modal-content {
      max-width: 100%;
      padding: 10px;
    }
  
    .submit-btn,
    .close-btn {
      padding: 8px 16px;
      font-size: 12px;
    }
  }

  .status-message {
    font-family: 'Poppins';
    text-align: center;
    padding: 20px;
    color: black; /* Ensure text color is black */
  }
  
  .status-message.success {
    color: black; /* Black text color for success messages */
  }
  
  .status-message.error {
    color: black; /* Black text color for error messages */
  }
  
  
  